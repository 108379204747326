<template>
  <v-container>
    <GoBackBtn :routeToGo="routeToGo" />
    <PageHeader :title="isEditing ? `${titleEdit}` : titleNew" />
    <v-card>
      <div class="row">
        <v-container class="pt-0">
          <v-form v-model="isFormValid">
            <v-container>
              <v-row>
                <v-col cols="4" sm="4" md="4" class="py-0">
                  <v-textarea
                    auto-grow
                    rows="1"
                    clearable
                    v-model.trim="nombre"
                    label="Nombre del formato"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(nombre),
                        rules.maxLength(nombre, 100),
                      ])
                    "
                  >
                  </v-textarea>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="py-0">
                  <v-autocomplete
                    v-model="servidor"
                    label="Servidor"
                    :items="servidores"
                    item-text="value"
                    item-value="id"
                    dense
                    outlined
                    clearable
                    :rules="rules.required"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="4" md="4" class="py-0">
                  <v-autocomplete
                    v-model="entidadFacturanteSelected"
                    :items="entidadesFacturantes"
                    label="Entidad facturante"
                    item-text="value"
                    item-value="id"
                    outlined
                    clearable
                    dense
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-text-field
                    v-model.trim="nombreRemitente"
                    label="Nombre remitente"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(nombreRemitente),
                        rules.maxLength(nombreRemitente, 100),
                      ])
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="4" sm="6" md="4" class="py-0">
                  <v-text-field
                    v-model.trim="emailRemitente"
                    label="Email remitente"
                    dense
                    outlined
                    :rules="
                      rules.required.concat([
                        rules.requiredTrim(emailRemitente),
                        rules.maxLength(emailRemitente, 100),
                        rules.validateEmailFormat(emailRemitente),
                      ])
                    "
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="4"
                  sm="6"
                  md="4"
                  class="d-flex py-0 justify-content-center"
                >
                  <v-checkbox
                    v-model="activo"
                    id="switch1"
                    class="mt-1"
                  ></v-checkbox>
                  <label for="switch1" class="mt-2">Activo</label>
                  <v-spacer></v-spacer>
                </v-col>
              </v-row>
            </v-container>
            <div class="col-12 py-0">
              <v-col cols="12" sm="12" md="12" class="pa-0 py-0">
                <v-text-field
                  v-model="asunto"
                  label="Asunto"
                  dense
                  outlined
                  :rules="
                    rules.required.concat([
                      rules.requiredTrim(asunto),
                      rules.maxLength(asunto, 150),
                    ])
                  "
                >
                </v-text-field>
              </v-col>
              <TextEditor
                ref="text"
                :value.sync="content"
              />
            </div>
            <v-container>
              <v-row class="pr-4 pl-4 pt-4" justify="end">
                <v-btn
                  color="primary"
                  :disabled="!isFormValid"
                  :loading="loadingSaveBtn"
                  @click="saveContent"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-container>
          </v-form>
        </v-container>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import PageHeader from "@/components/ui/PageHeader";
// import TextEditor from "@/components/textEditor/TextEditor.vue";
import TextEditor from "@/components/shared/TextEditor.vue";
import GoBackBtn from "@/components/shared/GoBackBtn";

export default {
  name: "EditFormatoEmailOrdenesPago",
  components: {
    PageHeader,
    TextEditor,
    GoBackBtn,
  },
  data() {
    return {
      routeToGo: "FormatosEmailOrdenesPago",
      titleNew: enums.titles.NUEVO_FORMATO_EMAIL,
      titleEdit: enums.titles.EDITAR_FORMATO_EMAIL,
      content: "",
      contentChanged: false,
      rules: rules,
      isFormValid: false,
      nombre: null,
      nombreRemitente: null,
      emailRemitente: null,
      servidores: [],
      servidor: null,
      emailId: null,
      asunto: null,
      activo: false,
      codigo: "OPRET",
      entidadesFacturantes: [],
      entidadFacturanteSelected: null,
      loadingSaveBtn: false,
    };
  },
  created() {
    this.setSelects();
    if (this.$route.params.formato) {
      this.setFormato();
    }
  },
  computed: {
    isEditing() {
      return this.$route.params.formato;
    },
  },
  methods: {
    ...mapActions({
      fetchServidores: "configuracion/fetchServidores",
      fetchVariables: "configuracion/fetchVariables",
      saveEmail: "configuracion/saveEmail",
      getEntidadesFacturantes: "devengamientos/getEntidadesFacturantes",
      setAlert: "user/setAlert",
    }),
    async setSelects() {
      const servidores = await this.fetchServidores();
      this.servidores = servidores;
      const entFacs = await this.getEntidadesFacturantes();
      this.entidadesFacturantes = entFacs;
      this.entidadesFacturantes.unshift({ id: -1, value: "Todas", label: "" });
    },
    setFormato() {
      const template = this.$route.params.formato;
      this.emailId = template.emailId;
      this.content = template.cuerpoHtml;
      this.nombre = template.nombre;
      this.nombreRemitente = template.nombreRemitente;
      this.emailRemitente = template.emailRemitente;
      this.servidor = template.esId;
      this.asunto = template.asunto;
      if (template.entFacId === null) this.entidadFacturanteSelected = -1;
      else this.entidadFacturanteSelected = template.entFacId;
      this.activo = template.activo;
    },
    async saveContent() {
      let res = null;
      if (this.content.trim() === null || this.content.trim() === "") {
        this.setAlert({
          type: "warning",
          message: "El cuerpo del email está vacío.",
        });
      } else {
        this.loadingSaveBtn = true;

        // Se achica el interlineado para la impresion
        // TODO: buscar los nodos p en el html, ya que si tiene algun style ya aplicado no va a encontrar el tag <p>
        this.content = this.content.replaceAll(/<p><\/p>/g, '<p><br /></p>');
        this.content = this.content.replaceAll(/<p>/g, '<p style="margin: 2px 0px">');

        const data = {
          emailId: this.emailId,
          nombre: this.nombre,
          nombreRemitente: this.nombreRemitente,
          emailRemitente: this.emailRemitente,
          codigo: this.codigo,
          esId: this.servidor,
          asunto: this.asunto,
          entFacId:
            this.entidadFacturanteSelected === -1
              ? null
              : this.entidadFacturanteSelected,
          activo: this.activo,
          cuerpoHtml: this.content,
        };
        try {
          res = await this.saveEmail(data);
          if (res.status === 200) {
            this.setAlert({ type: "success", message: "Guardado con éxito" });
            this.$router.push({ name: this.routeToGo });
          }
        } catch (error) {
          this.loadingSaveBtn = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
